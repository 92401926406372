<template>
  <div ref="linkMenu">
    <div v-if="computedToolbar.includes('link')">
      <div class="bubble-menu" v-if="showLinkButton">
        <p-button color-type="tertiary" icon="link" @click="triggerOpenLinkModal">Link...</p-button>
      </div>
      <div class="bubble-menu" v-else-if="showActiveLinkOptions">
        <p-button color-type="tertiary" icon="link" @click="triggerOpenLinkModal">Link...</p-button>
        <p-button color-type="tertiary" icon="trash" @click="removeLink">Remove link</p-button>
        <p-button color-type="tertiary" icon="external-link" @click="openLink">Open link</p-button>
      </div>

      <div v-if="showImageButton">
        <p-button color-type="tertiary" icon="image" @click="openImageDetailsModal">Image...</p-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import tippy, { Instance as TippyInstance } from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // Base styles for bubble menu
import 'tippy.js/themes/light.css'; // Light theme for bubble menu
import { Editor } from '@tiptap/core';

@Component
export default class EditorBubbleMenu extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;
  @Prop({ type: Array, required: true }) public readonly computedToolbar!: string[];

  private editorTippyInstance: TippyInstance | null = null;

  public mounted() {
    this.initializeTippy();
  }

  public beforeDestroy() {
    if (this.editorTippyInstance) {
      this.editorTippyInstance.destroy();
    }
  }

  public get showLinkButton(): boolean {
    return !this.editor.isActive('link') && (this.editor.isActive('paragraph') || this.editor.isActive('heading'));
  }

  public get showActiveLinkOptions(): boolean {
    return this.editor.isActive('link');
  }

  public get showImageButton(): boolean {
    return this.computedToolbar.includes('mediaSelector') && this.editor.isActive('image');
  }

  public initializeTippy() {
    if (this.$refs.linkMenu instanceof HTMLElement && this.editor.options.element instanceof HTMLElement) {
      this.editorTippyInstance = tippy(this.editor.options.element, {
        content: this.$refs.linkMenu,
        allowHTML: true,
        placement: 'right-start',
        trigger: 'manual',
        theme: 'light',
        interactive: true,
        arrow: false,
        hideOnClick: true,
        appendTo: () => document.body,
        offset: [0, 0],
        onHide: () => {
          if (this.$refs.linkMenu instanceof HTMLElement) {
            this.$refs.linkMenu.style.display = 'none';
          }
        }
      });

      this.editor.options.element.addEventListener('contextmenu', (event: MouseEvent) => {
        event.preventDefault();
        this.updateLinkMenuVisibility();

        if (!this.editorTippyInstance) {
          return;
        }

        this.editorTippyInstance.setProps({
          getReferenceClientRect: () => {
            return new DOMRect(
              event.clientX,
              event.clientY,
              0, // width
              0 // height
            );
          }
        });

        this.editorTippyInstance.show();
      });
    }
  }

  public updateLinkMenuVisibility() {
    if (this.$refs.linkMenu instanceof HTMLElement) {
      this.$refs.linkMenu.style.display = 'block';
    }
  }

  public removeLink() {
    if (this.editor.isActive('link')) {
      this.editor.commands.unsetLink();
    }
  }

  public openLink() {
    if (this.editor.isActive('link')) {
      const linkAttributes = this.editor.getAttributes('link');
      const url = linkAttributes.href;

      if (url) {
        window.open(url, '_blank');
      }
    }
  }

  public triggerOpenLinkModal() {
    this.$emit('triggerOpenLinkModal');
  }

  public openImageDetailsModal() {
    this.$emit('openImageDetailsModal');
  }
}
</script>

<style lang="scss" scoped>
.bubble-menu {
  display: flex;
  flex-direction: column;
}
</style>
