<template>
  <p-accordion
    :title="element.properties.title"
    :description="element.properties.description"
    :icon="element.properties.icon"
    :number="element.properties.number"
    :level="element.properties.level"
    @update:open="onOpenValueChange"
    v-model="open"
  >
    <p-container>
      <layout-element v-for="child in children" :element="child" :key="child.key" />
    </p-container>
  </p-accordion>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/main';
import { IElementAccordion } from '@/interfaces/element';
import { getElementsForRender } from '@/utility';

@Component({
  name: 'layout-element-accordion'
})
export default class extends Vue {
  @Prop() public element!: IElementAccordion;
  public open = false;

  public created() {
    let shouldOpen = this.element.properties.initiallyOpen ?? false;

    if (this.element.properties && this.element.properties.id) {
      const collapsedState = this.getCollapsedStates();

      if (typeof collapsedState[this.element.properties.id] !== 'undefined') {
        shouldOpen = collapsedState[this.element.properties.id];
      }
    }

    this.open = shouldOpen;
  }

  public onOpenValueChange(isOpened: boolean) {
    if (!this.element.properties.id) {
      return;
    }

    const collapsedState = this.getCollapsedStates();

    collapsedState[this.element.properties.id] = isOpened;

    if (!collapsedState[this.element.properties.id]) {
      EventBus.$emit('SECTION_EXPAND', this.$el);
    }

    this.setCollapsedState(collapsedState);
  }

  public setCollapsedState(collapsed: any): void {
    try {
      localStorage.setItem('sections-collapsed', JSON.stringify(collapsed));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  public getCollapsedStates(): any {
    try {
      let sectionCollapseState = localStorage.getItem('sections-collapsed');

      if (sectionCollapseState !== null) {
        sectionCollapseState = JSON.parse(sectionCollapseState);
      }

      if (sectionCollapseState !== null && typeof sectionCollapseState === 'object') {
        return sectionCollapseState;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return {};
  }

  public get children() {
    return getElementsForRender(this.element.children ?? []);
  }
}
</script>
