<template>
  <transition name="appear-transition" appear>
    <div class="page" :class="{ 'page--top-spacing': topSpacing }">
      <p-container>
        <slot />
      </p-container>
    </div>
  </transition>
</template>

<script lang="ts">
import { Snowplow } from '@/snowplow/Snowplow';
import { useUserStore } from '@/store/userStore';
import { SelfDescribingJson } from '@snowplow/browser-tracker';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { samPageView } from '@/utility/analytics';

@Component({})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) public readonly topSpacing!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly sendAnalytics!: boolean;

  private userStore = useUserStore();
  private userSubscriptionUnsubscribe: (() => void) | null = null;
  private pageViewSent = false;

  public mounted() {
    if (this.sendAnalytics) {
      this.userSubscriptionUnsubscribe = this.userStore.$subscribe(() => {
        this.attemptSend();
      });

      this.attemptSend();
    }
  }

  public beforeUnmount() {
    if (this.userSubscriptionUnsubscribe) {
      this.userSubscriptionUnsubscribe();
    }
  }

  private attemptSend() {
    if (!this.sendAnalytics || this.pageViewSent) {
      return;
    }

    if (this.userStore.origin?.user.id && this.userStore.origin?.workspace.id) {
      this.pageViewSent = true;

      const context: Array<SelfDescribingJson> = [];

      if (this.$route.params.campaignId) {
        context.push({
          schema: 'iglu:com.playable/context_platform_campaign/jsonschema/1-0-0',
          data: {
            campaign_id: this.$route.params.campaignId
          }
        });
      }

      context.push({
        schema: 'iglu:com.playable/context_platform_user/jsonschema/1-0-0',
        data: {
          customer_id: this.userStore.origin.workspace.id,
          user_id: this.userStore.origin.user.id
        }
      });

      Snowplow.pageView({
        context
      });

      samPageView(this.userStore.origin.workspace.id, this.userStore.origin.user.id, this.$route.params.campaignId);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/devices';

.page {
  width: 1200px;
  margin: 0 auto;
  padding: 0 0 var(--base-size-1100) 0;

  &--top-spacing {
    padding-top: var(--gap-size-extra-large);
  }
}

.appear-transition {
  &-enter-active {
    transition: opacity 425ms linear, transform 425ms ease;
    transform-origin: center top;
    transform: translateY(0);
    opacity: 1;
  }

  &-enter {
    opacity: 0;
    transform: translateY(40px);
  }
}

@media print {
  .page {
    width: 100%;
  }
}

@include for-mobile-only {
  .page {
    width: 100%;

    &--top-spacing {
      padding-top: var(--gap-size-medium);
    }
  }
}
</style>
