<template>
  <p-container>
    <p-row>
      <p-form-select
        v-model="selectedEditor"
        size="small"
        :options="editorOptions"
        label="Editor"
        help-text="Use Text Editor for simple formatting or HTML Editor for full HTML customization"
      />
    </p-row>

    <p-container v-if="selectedEditor === 'codeEditor'">
      <p-message
        description="Changes made in the HTML editor may not display correctly if you switch back to the regular editor.  If you are using HTML customization, please complete all edits in the HTML editor."
        type="info"
      />
      <p-row>
        <p-form-code-editor
          :label="element.properties.label"
          :help-text="element.properties.help"
          :help-in-tooltip="element.properties.helpInTooltip"
          :error="element.properties.errors.join(', ')"
          :disabled="element.properties.disabled"
          :readonly="element.properties.readonly"
          :language="`html`"
          v-model="codeEditorValue"
        />
      </p-row>
      <p-accordion title="Preview">
        <p-row>
          <iframe
            width="100%"
            height="600"
            :srcdoc="iframeContent"
            class="preview-iframe"
            sandbox="allow-same-origin allow-scripts"
          ></iframe>
        </p-row>
      </p-accordion>
    </p-container>

    <p-row v-else>
      <p-form-text-editor
        :label="element.properties.label"
        :help-text="element.properties.help"
        :help-in-tooltip="element.properties.helpInTooltip"
        :error="element.properties.errors.join(', ')"
        :disabled="element.properties.disabled"
        :readonly="element.properties.readonly"
        :placeholder="element.properties.placeholder"
        :toolbar="[
          'bold',
          'italic',
          'underline',
          'blocks',
          'forecolor',
          'align',
          'numlist',
          'bullist',
          'link',
          'mediaSelector',
          'undo',
          'redo',
          'table'
        ]"
        :forced-root-block="element.properties.enterTag === 'BR' ? undefined : element.properties.enterTag"
        :sections="element.properties.sections"
        :popovers="element.properties.popovers"
        :size="element.properties.size"
        :ai-assistant="element.properties.aiAssistant"
        v-model="element.properties.value"
      />
    </p-row>
  </p-container>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementTextEditor } from '@/interfaces/element';
import LayoutElementTextEditor from '@/components/elements/LayoutElementTextEditor.vue';
import LayoutElementCodeEditor from '@/components/elements/LayoutElementCodeEditor.vue';
import debounce from 'lodash-decorators/debounce';
import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';

@Component({
  name: 'layout-element-email-body-editor',
  components: { LayoutElementCodeEditor, LayoutElementTextEditor }
})
export default class extends Vue {
  @Prop() public element!: IElementTextEditor;

  public editorOptions = [
    { text: 'HTML Editor', value: 'codeEditor' },
    { text: 'Text Editor', value: 'textEditor' }
  ];
  public selectedEditor = 'textEditor';

  private COMMENT = '<!-- email template code below -->';

  get codeEditorValue() {
    const value = this.element.properties.value || '';
    // eslint-disable-next-line security/detect-non-literal-regexp
    return value.replace(new RegExp(`^${this.COMMENT}\\s*`), '');
  }

  set codeEditorValue(newValue: string) {
    this.element.properties.value = `${this.COMMENT}\n${newValue.trimStart()}`;
  }

  public get iframeContent() {
    return `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <title>Email preview </title>
          <style>
          </style>
        </head>
        <body>
          ${this.element.properties.value}
        </body>
        </html>
      `;
  }

  @Watch('selectedEditor')
  onEditorChange() {
    const value = this.element.properties.value || '';
    if (this.selectedEditor === 'codeEditor' && !value.startsWith(this.COMMENT)) {
      this.element.properties.value = `${this.COMMENT}\n${value.trimStart()}`;
    } else if (this.selectedEditor === 'textEditor' && value.startsWith(this.COMMENT)) {
      // eslint-disable-next-line security/detect-non-literal-regexp
      this.element.properties.value = value.replace(new RegExp(`^${this.COMMENT}\\s*`), '');
    }
  }

  @Watch('element.properties.value')
  @debounce(375)
  onValueChange(newValue: string) {
    this.$el.dispatchEvent(new CustomEvent('BLUEPRINT_INTERACT', { bubbles: true, composed: true }));

    if (this.element.properties.trigger?.type) {
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, newValue);
    }
  }

  @Watch('element.properties.value')
  onFastValueChange() {
    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }
  }

  public mounted() {
    this.selectedEditor = this.element.properties.value?.includes(this.COMMENT) ? 'codeEditor' : 'textEditor';
  }
}
</script>
