<template>
  <div class="color-picker">
    <p-button
      color-type="tertiary"
      icon="color-picker"
      title="Color picker"
      @click="triggerColorPicker"
      :class="buttonClass"
    />
    <input type="color" ref="colorInputRef" @input="onColorInput" :value="colorValue" class="color-input" />
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Editor } from '@tiptap/core';

@Component
export default class EditorColorPicker extends Vue {
  @Prop({ type: Object, required: true }) public readonly editor!: Editor;

  // Computed property for the button class
  public get buttonClass() {
    return { 'toolbar-button--active': this.editor.isActive('color') };
  }

  // Computed property for the input value
  public get colorValue() {
    return this.editor.getAttributes('textStyle').color || '#000000';
  }

  public triggerColorPicker() {
    if (this.$refs.colorInputRef instanceof HTMLInputElement) {
      this.$refs.colorInputRef.click();
    }
  }

  public onColorInput(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      this.editor.chain().focus().setColor(event.target.value).run();
    }
  }
}
</script>

<style lang="scss" scoped>
.color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  input {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    width: 24px;
    height: 3px;
    padding: 0;
    cursor: pointer;
    border-radius: 4px;
    overflow: hidden;
    background: none;
    position: absolute;
    bottom: 3px;

    &::-webkit-color-swatch {
      border: none; /* Remove default border */
    }
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      border: none;
    }
    &::-moz-color-swatch {
      border: none;
    }
    &:hover {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.2); /* Add a hover effect */
    }
  }
}
</style>
