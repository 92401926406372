<template>
  <div class="template" :data-name="template.name">
    <component :is="'template-' + template.name.replace('_', '-')" :template="template">
      <slot />
    </component>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ILayoutTemplate } from '@/interfaces/template';
import TemplateLogin from '@/templates/TemplateLogin.vue';
import TemplateNotFound from '@/templates/TemplateNotFound.vue';
import TemplateDefault from '@/templates/TemplateDefault.vue';
import TemplateInternalServerError from '@/templates/TemplateInternalServerError.vue';
import TemplateIframe from '@/templates/TemplateIframe.vue';
import TemplateEmpty from '@/templates/TemplateEmpty.vue';
import TemplateIframeWithSave from '@/templates/TemplateIframeWithSave.vue';
import TemplateFastTrackEmbed from '@/templates/TemplateFastTrackEmbed.vue';
import TemplateError from '@/templates/TemplateError.vue';
import TemplatePublic from '@/templates/TemplatePublic.vue';
import TemplatePopup from '@/templates/TemplatePopup.vue';
import TemplateInvitationActivate from '@/templates/TemplateInvitationActivate.vue';
import TemplateCreateAccount from '@/templates/TemplateCreateAccount.vue';
import TemplateEmailPreview from '@/templates/TemplateEmailPreview.vue';
import TemplateUnauthorized from '@/templates/TemplateUnauthorized.vue';
import { Snowplow } from '@/snowplow/Snowplow';
import { SelfDescribingJson } from '@snowplow/tracker-core';
import { PropType } from 'vue';
import { disableIntercom, enableIntercom } from '@/utility/intercom';
import { samPageView } from '@/utility/analytics';

@Component({
  components: {
    TemplateNotFound,
    TemplateLogin,
    TemplateDefault,
    TemplateInternalServerError,
    TemplateIframe,
    TemplateIframeWithSave,
    TemplateFastTrackEmbed,
    TemplateError,
    TemplatePublic,
    TemplatePopup,
    TemplateEmpty,
    TemplateInvitationActivate,
    TemplateCreateAccount,
    TemplateEmailPreview,
    TemplateUnauthorized
  },
  name: 'layout-template'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<ILayoutTemplate>, required: true }) public readonly template!: ILayoutTemplate;

  created() {
    if (typeof (window as any).dataLayer === 'undefined') {
      (window as any).dataLayer = [];
    }

    this.dataLayerHandler();
  }

  @Watch('template')
  onTemplateChange() {
    this.dataLayerHandler();
  }

  // eslint-disable-next-line require-await
  async dataLayerHandler() {
    if (this.template?.params?.dataLayer) {
      const context: Array<SelfDescribingJson> = [];

      if (this.template?.params?.dataLayer.campaignId) {
        context.push({
          schema: 'iglu:com.playable/context_platform_campaign/jsonschema/1-0-0',
          data: {
            campaign_id: this.template.params.dataLayer.campaignId
          }
        });
      }

      if (this.template?.params?.dataLayer.userId && this.template?.params?.dataLayer.customerId) {
        context.push({
          schema: 'iglu:com.playable/context_platform_user/jsonschema/1-0-0',
          data: {
            customer_id: this.template.params.dataLayer.customerId,
            user_id: this.template.params.dataLayer.userId
          }
        });

        samPageView(
          this.template.params.dataLayer.customerId,
          this.template.params.dataLayer.userId,
          this.template?.params?.dataLayer.campaignId
        );
      }

      Snowplow.pageView({
        context
      });

      (window as any).dataLayer.push(this.template?.params?.dataLayer);
      (window as any).dataLayer.push({
        event: 'virtualPageDataLayerReady'
      });

      if (this.template.name !== 'iframe') {
        this.injectIntercom(this.template?.params?.dataLayer || {});
      } else {
        disableIntercom();
      }
    } else if (this.template.name === 'default') {
      // If template is default and no dataLayer variables is available
      // we need to let tag manager know through an event.
      (window as any).dataLayer.push({
        event: 'virtualPageDataLayerRemoval'
      });

      disableIntercom();

      if (typeof (window as any)._na !== 'undefined') {
        (window as any)._na.logout();
        delete (window as any)._na;
      }
    }
  }

  injectIntercom(dataLayer: any) {
    if (!dataLayer.customerHasChatAccess) {
      disableIntercom();
      return;
    }

    enableIntercom({
      user: {
        id: dataLayer.userId,
        name: dataLayer.userName,
        email: dataLayer.userEmail,
        hash: dataLayer.userHash,
        created_at: dataLayer.userCreatedOn,
        last_login: dataLayer.userLastLogin
      },
      company: {
        id: dataLayer.customerId,
        name: dataLayer.customerName,
        created_at: dataLayer.customerCreatedOn,
        plan: dataLayer.platformPlan,
        upgraded_at: dataLayer.customerUpgradedOn,
        license_period_start_at: dataLayer.customerLicensePeriodStart,
        license_period_end_at: dataLayer.customerLicensePeriodEnd,
        hubspot_company_id: dataLayer.hubspotCompanyId ? dataLayer.hubspotCompanyId : undefined
      }
    });
  }
}
</script>
