var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.show)?_c('p-modal-plain',{attrs:{"position":"top","headline":_vm.editMode ? 'Edit link' : 'Insert link'},domProps:{"show":true},on:{"close-request":_vm.cancelLink}},[_c('p-row',{attrs:{"justify-content":"center"}},[_c('p-column',{attrs:{"size":"medium"}},[_c('p-container',[_c('p-form-select',{attrs:{"label":"Link Type","options":[
              { text: 'URL', value: 'url' },
              _vm.popovers && _vm.popovers.length > 0 && { text: 'Open popovers', value: 'popover' },
              _vm.popovers && _vm.popovers.length > 0 && { text: 'Close popover', value: 'close-popover' },
              _vm.sections.length > 0 && { text: 'Go to section', value: 'section' }
            ].filter(Boolean),"placeholder":"Select link type","size":"medium"},model:{value:(_vm.linkType),callback:function ($$v) {_vm.linkType=$$v},expression:"linkType"}}),_vm._v(" "),(_vm.linkType === 'url')?_c('p-form-input',{attrs:{"label":"URL","placeholder":"Enter URL","size":"medium"},model:{value:(_vm.linkUrl),callback:function ($$v) {_vm.linkUrl=$$v},expression:"linkUrl"}}):_vm._e(),_vm._v(" "),(_vm.linkType === 'popover')?_c('p-form-select',{attrs:{"label":"Select Popover","options":_vm.popoverOptions,"placeholder":"Select a popover","size":"medium"},model:{value:(_vm.selectedPopover),callback:function ($$v) {_vm.selectedPopover=$$v},expression:"selectedPopover"}}):_vm._e(),_vm._v(" "),(_vm.linkType === 'section')?_c('p-form-select',{attrs:{"label":"Select Section","options":_vm.sectionOptions,"placeholder":"Select a section","size":"medium"},model:{value:(_vm.selectedSection),callback:function ($$v) {_vm.selectedSection=$$v},expression:"selectedSection"}}):_vm._e(),_vm._v(" "),(!_vm.editor.isActive('image') && !_vm.hasMultipleParagraphs)?_c('p-form-input',{attrs:{"label":"Text to Display","placeholder":"Text to Display","size":"medium"},model:{value:(_vm.linkText),callback:function ($$v) {_vm.linkText=$$v},expression:"linkText"}}):_vm._e(),_vm._v(" "),_c('p-form-input',{attrs:{"type":"text","label":"Title","placeholder":"Title","size":"medium"},model:{value:(_vm.linkTitle),callback:function ($$v) {_vm.linkTitle=$$v},expression:"linkTitle"}}),_vm._v(" "),(_vm.linkType === 'url')?_c('p-form-select',{attrs:{"label":"Open link in...","options":[
            { text: 'Open in current window', value: '_self' },
            { text: 'Open in new window', value: '_blank' }
          ],"placeholder":"Select link target","size":"medium"},model:{value:(_vm.linkTarget),callback:function ($$v) {_vm.linkTarget=$$v},expression:"linkTarget"}}):_vm._e(),_vm._v(" "),_c('p-row',{attrs:{"justify-content":"flex-end"}},[_c('p-button',{attrs:{"type":"default","color-type":"tertiary"},on:{"click":_vm.cancelLink}},[_vm._v("Cancel")]),_vm._v(" "),_c('p-button',{attrs:{"type":"default","color-type":"primary"},on:{"click":_vm.insertLink}},[_vm._v("Save")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }