<template>
  <p-form-card
    :label="element.properties.label"
    :help-text="element.properties.help"
    :disabled="element.properties.disabled"
    :error="element.properties.errors.join(', ')"
    :cards="element.properties.cards"
    :justify-content="element.properties.justifyContent"
    v-model="element.properties.value"
    @update:modelValue="onInteract()"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementCard } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import { Iframe } from '@/iframe';
import { PropType } from 'vue';

@Component({
  name: 'layout-element-card'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementCard>, required: true }) public readonly element!: IElementCard;

  private didInteract = false;

  public onInteract() {
    this.didInteract = true;

    setTimeout(() => {
      this.didInteract = false;
    }, 625);
  }

  @Watch('element.properties.value')
  public onChange() {
    if (!this.didInteract) {
      return;
    }

    if (this.element.properties.name) {
      Iframe.valueChange(this.element.properties.name, this.element.properties.value);
    }

    this.$el.dispatchEvent(
      new CustomEvent('BLUEPRINT_INTERACT', {
        bubbles: true,
        composed: true
      })
    );

    if (this.element.properties.trigger && this.element.properties.trigger.type) {
      Trigger.handle(
        this.element.properties.trigger,
        this.$el,
        this.element.properties.name,
        this.element.properties.value
      );
    }
  }
}
</script>
