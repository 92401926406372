<template>
  <div class="text-editor" ref="instance">
    <editor-toolbar
      ref="toolbar"
      v-if="editor"
      :editor="editor"
      :isInModal="isInModal"
      :computedToolbar="computedToolbar"
      :sections="sections"
      :popovers="popovers"
      :modelValue="modelValue"
      @updateSourceCode="handleSourceCodeUpdate"
    />

    <editor-bubble-menu
      v-if="editor"
      ref="linkMenu"
      :editor="editor"
      :computedToolbar="computedToolbar"
      @triggerOpenLinkModal="triggerOpenLinkModal"
      @openImageDetailsModal="openImageDetailsModal"
    />

    <div ref="editorRef" class="playable-editor" @click="focusEditor" :style="{ height: `${editorHeight}px` }"></div>

    <div class="editor-footer" @mousedown="startResizing">
      <svg width="10" height="10" focusable="false">
        <g fill-rule="nonzero">
          <path
            d="M8.1 1.1A.5.5 0 1 1 9 2l-7 7A.5.5 0 1 1 1 8l7-7ZM8.1 5.1A.5.5 0 1 1 9 6l-3 3A.5.5 0 1 1 5 8l3-3Z"
          ></path>
        </g>
      </svg>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { PropType } from 'vue';
import { EditorPageOption } from '../types';
import { Editor } from '@tiptap/core';
import { StarterKit } from '@tiptap/starter-kit';
import { Underline } from '@tiptap/extension-underline';
import { TextStyle } from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';

// Custom class extending Link to allow/add a title attribute to href
import CustomLink from '@/components/ui/form/texteditor/extensions/CustomLink';
// Custom iframe extension to allow iframes in the editor
import CustomIframe from '@/components/ui/form/texteditor/extensions/CustomIframe';
import CustomTextAlign from '@/components/ui/form/texteditor/extensions/CustomTextAlign'; // Light theme for bubble menu
import CustomImage from '@/components/ui/form/texteditor/extensions/CustomImage';

import EditorBubbleMenu from '@/components/ui/form/texteditor/EditorBubbleMenu.vue';
import EditorToolbar from '@/components/ui/form/texteditor/EditorToolbar.vue';
import { CustomDiv } from '@/components/ui/form/texteditor/extensions/CustomDiv';
import { CustomParagraph } from '@/components/ui/form/texteditor/extensions/CustomParagraph';
import { CustomHeading } from '@/components/ui/form/texteditor/extensions/CustomHeading';
import { CustomSpan } from '@/components/ui/form/texteditor/extensions/CustomSpan';
import { TableRow } from '@tiptap/extension-table-row';
import { CustomTable } from '@/components/ui/form/texteditor/extensions/CustomTable';
import { CustomTableHeader } from '@/components/ui/form/texteditor/extensions/CustomTableHeader';
import { CustomTableCell } from '@/components/ui/form/texteditor/extensions/CustomTableCell';
import { Highlight } from '@tiptap/extension-highlight';
import { Code } from '@tiptap/extension-code';
import { CustomAudio } from '@/components/ui/form/texteditor/extensions/CustomAudio';
import { CustomVideo } from '@/components/ui/form/texteditor/extensions/CustomVideo';
import { CustomSup } from '@/components/ui/form/texteditor/extensions/CustomSup';
import { CustomListItem } from '@/components/ui/form/texteditor/extensions/CustomListItem';
@Component({
  components: {
    EditorToolbar,
    EditorBubbleMenu
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: true }) public readonly modelValue!: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly error?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Array as PropType<string[]>, required: false, default: () => [] }) public readonly toolbar!: string[];
  @Prop({ type: Array as PropType<EditorPageOption[]>, required: false, default: () => [] })
  public readonly sections!: EditorPageOption[];
  @Prop({ type: Array as PropType<EditorPageOption[]>, required: false, default: () => [] })
  public readonly popovers!: EditorPageOption[];

  public editor: Editor | null = null;
  public editorHeight = 300;
  public resizing = false;
  public startY = 0;
  public startHeight = 0;
  public isInModal = false;

  public get computedToolbar() {
    return this.toolbar.length > 0
      ? this.toolbar
      : [
          'bold',
          'italic',
          'underline',
          'blocks',
          'forecolor',
          'align',
          'numlist',
          'bullist',
          'link',
          'mediaSelector',
          'undo',
          'redo',
          'code',
          'table'
        ];
  }

  public handleSourceCodeUpdate() {
    if (!this.editor) return;
    this.$emit('update:modelValue', this.editor.getHTML());
  }

  public startResizing(event: MouseEvent) {
    this.resizing = true;
    this.startY = event.clientY;
    this.startHeight = this.editorHeight;

    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.stopResizing);
  }

  public onMouseMove(event: MouseEvent) {
    if (this.resizing) {
      const deltaY = event.clientY - this.startY;

      // Only adjust the height
      this.editorHeight = this.startHeight + deltaY;
    }
  }

  public stopResizing() {
    this.resizing = false;
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.stopResizing);
  }

  public openImageDetailsModal() {
    if (this.$refs.toolbar instanceof EditorToolbar) {
      this.$refs.toolbar.openImageDetailsModal();
    }
  }

  public triggerOpenLinkModal() {
    if (this.$refs.toolbar instanceof EditorToolbar) {
      this.$refs.toolbar.triggerOpenLinkModal();
    }
  }

  // The HardBreak extensions adds support for the <br> HTML tag, which forces a line break.
  public initializeEditor() {
    if (this.$refs.editorRef instanceof HTMLElement) {
      this.editor = new Editor({
        element: this.$refs.editorRef,
        extensions: [
          StarterKit.configure({
            paragraph: false, // Exclude paragraph
            heading: false, // Exclude heading
            listItem: false // Exclude list item
          }),

          Underline,
          Color,
          TextStyle,
          CustomSpan,
          CustomParagraph,
          CustomHeading,
          CustomImage.configure({
            inline: true // Allow inline images
          }),
          CustomLink.configure({
            openOnClick: false
          }),
          CustomTextAlign.configure({
            types: ['heading', 'paragraph', 'listItem', 'image', 'bulletList']
          }),
          CustomIframe,
          CustomDiv,
          // Add the table extensions
          CustomTable,
          TableRow,
          CustomTableCell,
          CustomTableHeader,
          CustomSup,

          Highlight.configure({
            multicolor: true
          }),
          Code,
          CustomAudio,
          CustomVideo,
          CustomListItem
        ],
        content: this.modelValue,
        editable: !this.disabled,
        editorProps: {
          // special handling of paste text
          // if multiple lines are pasted, they are wrapped in <p> tags
          // else the text is pasted as is
          handlePaste: (view, event, _slice) => {
            event.preventDefault();
            if (!this.editor || !event.clipboardData) return true;

            const text = event.clipboardData.getData('text/plain');

            if (!/\r?\n/.test(text)) {
              // Single-line text: insert as text node
              this.editor.commands.insertContent({
                type: 'text',
                text: text
              });
            } else {
              // Multi-line text: split into lines and filter out empty lines
              const lines = text.split(/\r?\n/).filter((line) => line.trim() !== '');

              // Map non-empty lines to paragraph nodes
              const nodes = lines.map((line) => ({
                type: 'paragraph',
                content: [
                  {
                    type: 'text',
                    text: line
                  }
                ]
              }));

              // Insert the array of nodes into the editor
              this.editor.commands.insertContent(nodes);
            }

            return true; // Indicate that the event has been handled
          }
        },
        onUpdate: ({ editor }) => {
          const html = editor.getHTML();
          // eslint-disable-next-line security/detect-unsafe-regex
          const onlyEmptyParagraph = /^<p(\s+[^>]*)?><\/p>$/i.test(html);
          const isEmpty = onlyEmptyParagraph || html.trim() === '' || html === '<p><br data-mce-bogus="1"></p>';
          this.$emit('update:modelValue', isEmpty ? '' : html);
        }
      });
    }

    this.styleEditor();
  }

  public styleEditor() {
    // Get reference element styles
    const referenceElement = this.$refs.instance instanceof HTMLDivElement ? this.$refs.instance : this.$el;
    const defaultColor = window.getComputedStyle(referenceElement).color;
    const fontSize = window.getComputedStyle(referenceElement).fontSize;
    const lineHeight = window.getComputedStyle(referenceElement).lineHeight;

    // Creates a <style> tag with the dynamically generated styles
    // trying to match current tinymce styles
    const styleTag = document.createElement('style');
    styleTag.innerHTML = `
    .playable-editor {
      color: ${defaultColor};
      font-size: ${fontSize};
      line-height: ${lineHeight};
    }
  `;

    // Inject the style tag into the editor's content area
    if (this.$refs.editorRef instanceof HTMLElement) {
      this.$refs.editorRef.appendChild(styleTag);
    }
  }

  public focusEditor() {
    if (this.$refs.linkMenu && this.$refs.linkMenu instanceof HTMLElement) {
      this.$refs.linkMenu.style.display = 'none';
    }

    if (this.editor) {
      if (this.editor.isFocused) {
        return;
      }
      this.editor.commands.focus('end');
    }
  }

  public mounted() {
    this.isInModal = document.querySelectorAll('p-modal-plain').length === 1;
    this.initializeEditor();
  }

  public beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
    }
  }

  @Watch('disabled')
  public onDisabledChange(newVal: boolean) {
    if (this.editor) {
      this.editor.setEditable(!newVal);
    }
  }

  @Watch('modelValue')
  public onModelValueChange(newValue: string) {
    if (this.editor && newValue !== this.editor.getHTML()) {
      this.editor.commands.setContent(newValue);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';

.text-editor {
  width: 100%;
  margin-top: 10px;
  background: #ffffff;

  @include text-body-default;

  ::v-deep {
    .playable-editor {
      border-left: 1px solid var(--field-color-border-default);
      border-right: 1px solid var(--field-color-border-default);
      overflow: hidden;
      overflow-y: scroll;
      min-height: 50px;

      a {
        color: #586f7c;
      }

      .mce-content-body [data-mce-selected='inline-boundary'] {
        background-color: transparent;
      }

      sup {
        top: -0.5em;
      }

      sub,
      sup {
        position: relative;
        font-size: 75%;
        line-height: 0;
        vertical-align: baseline;
      }

      /* List styles */
      ol,
      ul,
      li {
        padding: 0;
        margin: 0;
      }

      ul,
      ol {
        list-style: none;
      }

      ul li {
        list-style: disc outside;
        margin-left: 20px;
      }

      ul ol {
        list-style: decimal outside;
        margin-left: 20px;
      }

      ul ul li,
      ol ul li {
        margin-block-start: 0px;
        margin-block-end: 0px;
        list-style-type: circle;
      }

      ul,
      ol {
        li {
          p {
            display: inline;
            margin: 0;
            padding: 0;
          }
        }
      }

      /* Typography */
      strong,
      b {
        font-weight: bold;
      }

      em {
        font-style: italic;
      }

      p {
        margin: 0 0 1em 0;
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }

      p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        unicode-bidi: isolate;
      }
      h1 {
        display: block;
        font-size: 2em;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
      }

      h2 {
        display: block;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
      }

      h3 {
        display: block;
        font-size: 1.17em;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
      }

      h4 {
        display: block;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
      }

      h5 {
        display: block;
        font-size: 0.83em;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
      }

      h6 {
        display: block;
        font-size: 0.67em;
        margin-block-start: 2.33em;
        margin-block-end: 2.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        unicode-bidi: isolate;
      }

      .tiptap {
        margin: 10px;
      }

      img.ProseMirror-selectednode {
        outline: 3px solid #3399ff;
      }
    }
  }
}

.editor-footer {
  width: 100%;
  border: 1px solid var(--field-color-border-default);
  bottom: 0;
  cursor: nwse-resize;
  height: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-bottom-left-radius: var(--field-border-radius-default);
  border-bottom-right-radius: var(--field-border-radius-default);

  svg {
    position: absolute;
    right: 5px;
  }
}

::v-deep {
  code {
    background-color: #e8e8e8;
    border-radius: 3px;
    padding: 0.1rem 0.2rem;
    font-family: monospace;
  }
}

// table styles
::v-deep {
  table,
  th,
  td {
    padding: 10px; /* Default padding, or set as needed */
    border-width: 1px;
    border-style: solid;
    border-color: #ccc;
    vertical-align: top;
  }

  th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: bold;
    text-align: -internal-center;
    unicode-bidi: isolate;
  }

  table[border]:not([border='0']):not([style*='border-color']) td,
  table[border]:not([border='0']):not([style*='border-color']) th {
    border-color: #ccc;
  }
  table[border]:not([border='0']):not([style*='border-style']) td,
  table[border]:not([border='0']):not([style*='border-style']) th {
    border-style: solid;
  }

  table[border]:not([border='0']):not([style*='border-width']) td,
  table[border]:not([border='0']):not([style*='border-width']) th {
    border-width: 1px;
  }

  td {
    display: table-cell;
    vertical-align: inherit;
    unicode-bidi: isolate;
  }
}
</style>
