var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p-container',[_c('p-row',[_c('p-form-select',{attrs:{"size":"small","options":_vm.editorOptions,"label":"Editor","help-text":"Use Text Editor for simple formatting or HTML Editor for full HTML customization"},model:{value:(_vm.selectedEditor),callback:function ($$v) {_vm.selectedEditor=$$v},expression:"selectedEditor"}})],1),_vm._v(" "),(_vm.selectedEditor === 'codeEditor')?_c('p-container',[_c('p-message',{attrs:{"description":"Changes made in the HTML editor may not display correctly if you switch back to the regular editor.  If you are using HTML customization, please complete all edits in the HTML editor.","type":"info"}}),_vm._v(" "),_c('p-row',[_c('p-form-code-editor',{attrs:{"label":_vm.element.properties.label,"help-text":_vm.element.properties.help,"help-in-tooltip":_vm.element.properties.helpInTooltip,"error":_vm.element.properties.errors.join(', '),"disabled":_vm.element.properties.disabled,"readonly":_vm.element.properties.readonly,"language":`html`},model:{value:(_vm.codeEditorValue),callback:function ($$v) {_vm.codeEditorValue=$$v},expression:"codeEditorValue"}})],1),_vm._v(" "),_c('p-accordion',{attrs:{"title":"Preview"}},[_c('p-row',[_c('iframe',{staticClass:"preview-iframe",attrs:{"width":"100%","height":"600","srcdoc":_vm.iframeContent,"sandbox":"allow-same-origin allow-scripts"}})])],1)],1):_c('p-row',[_c('p-form-text-editor',{attrs:{"label":_vm.element.properties.label,"help-text":_vm.element.properties.help,"help-in-tooltip":_vm.element.properties.helpInTooltip,"error":_vm.element.properties.errors.join(', '),"disabled":_vm.element.properties.disabled,"readonly":_vm.element.properties.readonly,"placeholder":_vm.element.properties.placeholder,"toolbar":[
        'bold',
        'italic',
        'underline',
        'blocks',
        'forecolor',
        'align',
        'numlist',
        'bullist',
        'link',
        'mediaSelector',
        'undo',
        'redo',
        'table'
      ],"forced-root-block":_vm.element.properties.enterTag === 'BR' ? undefined : _vm.element.properties.enterTag,"sections":_vm.element.properties.sections,"popovers":_vm.element.properties.popovers,"size":_vm.element.properties.size,"ai-assistant":_vm.element.properties.aiAssistant},model:{value:(_vm.element.properties.value),callback:function ($$v) {_vm.$set(_vm.element.properties, "value", $$v)},expression:"element.properties.value"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }